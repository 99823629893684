
import { User } from "@/store/user/types";
import { StateType, GenericState } from "@/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import { Timestamp, deleteDoc, doc, getDoc } from "firebase/firestore";
import { firestore } from "@/firebase";
import { format } from "date-fns";

@Component({
  components: {
    StateTypeSpan: () => import('@/components/typography/StateTypeSpan.vue'),
    ConfirmationDialog: () => import('@/components/dialogs/ConfirmationDialog.vue')
  }
})
export default class ProgramTimeline extends Vue {
  @Prop({ required: true }) states!: GenericState[];
  @Prop({ required: true }) programId!: string;

  get isAdmin() {
    return this.$store.getters[USER_GETTERS.ACTING_USER].type == 'ADMIN'
  }

  dialogs = {
    confirmDeleteState: false,
  }

  loadedUsers = false;

  selectedState = '';

  users: Record<string, User> = {};

  @Watch('states', { immediate: true })
  async onChange() {
    for (let u of this.states.map(e => e.user)) {
      if (u) {
        const user = await getDoc(doc(firestore, 'users', u))
        this.users[u] = {
          id: user.id,
          ...user.data()
        } as User
      }
    }
    this.loadedUsers = true;
  }

  async deleteState() {
    try {

      let state = this.states.find(e => e.id == this.selectedState)

      if (state) {

        await deleteDoc(doc(firestore, 'program-states', state.id))

        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'success',
          text: this.$t('deleted')
        })

        this.dialogs.confirmDeleteState = false;
        this.$emit('refresh')
      }


    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    }
  }

  formatDate(date: Timestamp) {
    return format(date.toDate(), 'dd/MM/yyyy')
  }

}
